const isDevelopment = process.env.NODE_ENV === 'development'
const apiServer = isDevelopment ? 'http://localhost:3010' : ''

/**
 * public api
 */

export class StateEnum {
    static Created = new StateEnum('created')
    static Instantiated = new StateEnum('instantiated')
    static Running = new StateEnum('running')
    static Stopped = new StateEnum('stopped')

    _name:string

    constructor( name:string ) {
        this._name = name
    }
}

export type MetaData = {
    [ key:string]: any
}

export function serializeMetadata( metadata:MetaData ):Uint8Array|undefined {
    let ret
    if ( _isMetadataValid( metadata ) ) {
        const newData:MetaData = {}
        newData.decoderConfig = Object.assign({}, metadata.decoderConfig )

        if( 'description' in metadata.decoderConfig ) {
            newData.decoderConfig.descriptionInBase64 = _arrayBufferToBase64( metadata.decoderConfig.description )
            delete newData.description
        }
        const encoder = new TextEncoder()
        ret = encoder.encode(JSON.stringify(newData))
    }
    return ret
}

export function deSerializeMetadata( metadata:Buffer) {
    const decoder = new TextDecoder()
    const str = decoder.decode( metadata )
    const data = JSON.parse(str)

    if(( 'decoderConfig' in data ) && ('descriptionInBase64' in data.decoderConfig )) {
        const description = _base64ToArrayBuffer( data.decoderConfig.descriptionInBase64 )
        data.decoderConfig = { ...data.decoderConfig, description }
        delete data.decoderConfig.descriptionInBase64
    }
    return data.decoderConfig
}

/**
 * root path の URL を取得する
 * 
 * @returns {string} root url - e.g. http://localhost:3000
 */
export function getRootUrl() {
    const location = window.location
    return `${location.protocol}//${location.host}`
}

type Landmark = {
    x: number;
    y: number;
    z: number;
}

export const getRadians = ( landmarks:Array<Landmark>, startIdx: number, endIdx: number ):{x:number,y:number,z:number} => {
    if( landmarks[endIdx].x <= 0 || landmarks[startIdx].x <= 0 || landmarks[endIdx].y <= 0 || landmarks[startIdx].y <= 0 ) {
        return { x: 0, y: 0, z: 0 }
    }
    const deltaX = landmarks[endIdx].x - landmarks[startIdx].x;
    const deltaY = landmarks[endIdx].y - landmarks[startIdx].y;
    const deltaZ = landmarks[endIdx].z - landmarks[startIdx].z;

    const ret = {
        x: Math.atan2(deltaY, deltaZ),
        y: Math.atan2(deltaZ, deltaX), 
        z: Math.atan2(deltaY, deltaX) > 0 ? Math.atan2(deltaY, deltaX) : Math.atan2(deltaY, deltaX)
    }
    return ret; 
}

export type TypeItemMetrics = {
    w?: number;
    h?: number;
    bps: number;
    fps: number;
    lost: number;
}

export type TypeMetrics = {
    type: string;
    video: TypeItemMetrics;
    audio: TypeItemMetrics;
    dante: TypeItemMetrics;
}

export const postMetrics = async (metrics:TypeMetrics) => {
    try {
        const data = { ...metrics, clientTs: Date.now() }
        await fetch(`${apiServer}/api/v1/metrics`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })
    } catch( e ) {
        console.error('failed to post metrics:%o', e)
    }
}

/**
 * 
 * @param date - e.g. `2024-12-10`
 */
export const getMetrics = async (date:string) => {
    return await fetch(`${apiServer}/api/v1/metrics/${date}`)
        .then( async res => {
            if( res.ok ) {
                const json = await res.json()
                return json
            } else {
                const text = await res.text()
                throw new Error(`Error: ${res.statusText}:${text}`)
            }
        })
}



export function signed24BitFromBytes(byte1:number, byte2:number, byte3:number) {
  // 3バイトを結合して24ビットの整数値にする
  const unsignedValue = (byte1 << 16) | (byte2 << 8) | byte3;

  // 符号ビットを確認（23ビット目）
  if (unsignedValue & 0x800000) { // 符号ビットが立っている場合
      return unsignedValue - 0x1000000; // 負の値に変換
  } else {
      return unsignedValue; // 正の値としてそのまま
  }
}




/**
 * private api
 * 
 */

function _isMetadataValid( metadata:MetaData ) {
    return metadata !== undefined && 'decoderConfig' in metadata
}

function _arrayBufferToBase64( buffer:Buffer ):string {
    let binary = ''
    const bytes = new Uint8Array( buffer )

    const len = bytes.byteLength
    for( let i = 0; i < len; i++ ) {
        binary += String.fromCharCode(bytes[i])
    }
    return btoa(binary)
}

function _base64ToArrayBuffer( base64:string ) {
    const binaryString = atob( base64 )
    const len = binaryString.length
    const bytes = new Uint8Array( len )
    for( let i = 0; i < len; i++ ) {
        bytes[i] = binaryString.charCodeAt(i)
    }
    return bytes.buffer
}