import { useRef, useCallback, useEffect, useState } from 'react'
import format from 'format-number'
import moment from 'moment-timezone'

type TypeData = {
    x: number;
    y: number;
}

type Props = {
    title: string;
    width?: number|string,
    height?: number|string,
    debug?: boolean,
    data: Array<TypeData>,
    delta?: boolean
}

const TIME_SPAN = 1_800_000

export const TimeSeriesGraph = ( props:Props ) => {
    const { width, height, debug, data, title, delta } = props
    const _canvas = useRef<HTMLCanvasElement>(null)
    const _cvs = useRef<CanvasRenderingContext2D|null>(null)

    const [ _yMax, setYMax ] = useState<number>(0)
    const [ _xMin, setXMin ] = useState<number>(0)
    const [ _xMax, setXMax ] = useState<number>(0)


    const _draw = useCallback(( _data:Array<TypeData>) => {
        if( _canvas.current && _cvs.current ) {
            if( debug ) console.log( 'draw:%o', _data )
            const w = _canvas.current.width
            const h = _canvas.current.height
            const pad = 20
            const _w = _canvas.current.width - ( pad << 1 )
            const _h = _canvas.current.height - ( pad << 1 )

            _cvs.current.fillStyle = 'black'
            _cvs.current.fillRect(0, 0, w, h)



            if (_data && _data.length > 0) {
                const x_max = Math.max( ..._data.map( ( item:TypeData ) => item.x ))
                const x_min = Math.min( ..._data.map( ( item:TypeData ) => item.x ))

                const min_scale = Math.ceil(x_min / TIME_SPAN) * TIME_SPAN
                const max_scale = Math.floor(x_max / TIME_SPAN) * TIME_SPAN

                const y_max = Math.max( ..._data.map( ( item:TypeData ) => item.y ))
                const y_min = 0

                const deltaX = x_max - x_min
                const deltaY = ( y_max - y_min ) || 1

                // 目盛りの描画
                _cvs.current.strokeStyle = 'white'
                _cvs.current.moveTo(pad, pad + _h )
                _cvs.current.lineTo(pad + _w, pad + _h )
                _cvs.current.stroke()
            
                for( let i = min_scale; i <= max_scale; i += TIME_SPAN ) {
                    _cvs.current.fillStyle = 'white'
                    _cvs.current.font = '10.5px sans-serif'
                    const __x = Math.floor( ( i - x_min ) / deltaX * _w )
                    _cvs.current.fillText( 
                        moment( i ).tz('Asia/Tokyo').format('HH:mm'), 
                        pad + __x - 10, 
                        h - 2 
                    )
                    _cvs.current.strokeStyle = 'white'
                    _cvs.current.moveTo( pad + __x, pad + _h )
                    _cvs.current.lineTo( pad + __x, pad + _h - 10 )
                    _cvs.current.stroke()
                }

                _cvs.current.strokeStyle = 'yellow'
                _cvs.current.beginPath()
                _cvs.current.moveTo(pad, pad)

                for (let i = 0; i < _data.length; i++) {
                    const x = _data[i].x
                    const y = _data[i].y
                    const _x = pad + Math.floor(  ( x - x_min ) / deltaX * _w )
                    const _y = pad + _h - Math.floor(  ( y - y_min ) / deltaY * _h )
                    if( i === 0 ) {
                        _cvs.current.moveTo(_x, _y)
                    } else {
                        _cvs.current.lineTo(_x, _y)
                    }
                }

                _cvs.current.stroke()

                setYMax( y_max )
                setXMin( x_min )
                setXMax( x_max )
            }
        }
    }, [ debug ])

    // canvas の初期化
    useEffect(() => {
        if( _canvas.current ) {
            _cvs.current = _canvas.current.getContext('2d')
            _canvas.current.width = _canvas.current.clientWidth
            _canvas.current.height = _canvas.current.clientHeight
            const _data = !delta ? data : data.map( ( item:TypeData, idx:number ) => {
                if( idx === 0 ) {
                    return { ...item, y: 0 }
                } else {
                    const _prev = data[idx - 1]
                    const span = ( item.x - _prev.x ) / 1000
                    return { ...item, y: Math.max( 0, item.y - _prev.y ) / span}
                }
            }, [])
            _draw( _data )
        }
    },[ _draw, data, delta ])



    return (
        <div className="PcmGraph"> 
            <h4>{title}</h4>
            <div>
                max:{format({round: 2})(_yMax)}, 
                from: {moment(_xMin).tz('Asia/Tokyo').format('MM/DD HH:mm:ss')},
                to: {moment(_xMax).tz('Asia/Tokyo').format('MM/DD HH:mm:ss')}
            </div>
            <canvas ref={_canvas} style={{ 'width': width, 'height': height}}/>
        </div>
    );
}
