import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Alert, Col, Row } from 'antd'
import { getMetrics } from "../libs/utils/utils";

import { TimeSeriesGraph } from "../components/time-series-graph";
import { TypeItemMetrics } from "../libs/utils/utils";

export type TypeMetrics = {
    ts: number;
    clientTs: number;
    type: string;
    video: TypeItemMetrics;
    audio: TypeItemMetrics;
    dante: TypeItemMetrics;
}



export default function Metrics() {
    const { date } = useParams<{ date: string }>();
    const [ _errMessage, setErrMessage ] = useState<string|null>(null)
    const [ _mainReceiverMetrics, setMainReceiverMetrics ] = useState<Array<TypeMetrics>>([])
    const [ _satelliteReceiverMetrics, setSatelliteReceiverMetrics ] = useState<Array<TypeMetrics>>([])

    useEffect(() => {
        if (typeof date === 'string' && date !== '') {
            getMetrics(date).then(async res => {
                if( Array.isArray( res.data ) && res.data.length > 0 ) {
                    console.log( res )
                    setMainReceiverMetrics( res.data
                        .filter( (item:TypeMetrics) => item.type === 'main-receiver' ) 
                    )
                    setSatelliteReceiverMetrics( res.data
                        .filter( (item:TypeMetrics) => item.type === 'satellite-receiver' ) 
                    )
                }
            }).catch( e => {
                setErrMessage(e.message)
            })
        }
    }, [ date ])
    return (
        <div className="Metrics">
            <h1>Metrics</h1>
            <div>date: {date} (in PST maybe...)</div>
            { _errMessage && <Alert message={_errMessage} type="error" /> }
            <h2>Main Receiver</h2>
            <Row gutter={16}>
                <Col span={24}>
                    <h3>Video</h3>
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="bps"
                        data={_mainReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.video.bps 
                        }) )}
                        width='100%'
                        height={200}
                    />
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="fps"
                        data={_mainReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.video.fps 
                        }) )}
                        width='100%'
                        height={200}
                    />
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="lost"
                        data={_mainReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.video.lost 
                        }) )}
                        width='100%'
                        height={200}
                        delta={true}
                    />
                </Col>
                <Col span={24}>
                    <h3>Dante</h3>
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="bps"
                        data={_mainReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.dante.bps 
                        }) )}
                        width='100%'
                        height={200}
                    />
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="chunk per sec"
                        data={_mainReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.dante.fps 
                        }) )}
                        width='100%'
                        height={200}
                    />
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="lost"
                        data={_mainReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.dante.lost 
                        }) )}
                        width='100%'
                        height={200}
                        delta={true}
                    />
                </Col>
            </Row>

            <h2>Satellite Receiver</h2>
            <Row gutter={16}>
                <Col span={24}>
                    <h3>Video</h3>
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="bps"
                        data={_satelliteReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.video.bps 
                        }) )}
                        width='100%'
                        height={200}
                    />
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="fps"
                        data={_satelliteReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.video.fps 
                        }) )}
                        width='100%'
                        height={200}
                    />
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="lost"
                        data={_satelliteReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.video.lost 
                        }) )}
                        width='100%'
                        height={200}
                        delta={true}
                    />
                </Col>
                <Col span={24}>
                    <h3>Audio</h3>
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="bps"
                        data={_satelliteReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.audio.bps 
                        }) )}
                        width='100%'
                        height={200}
                    />
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="chunk per sec"
                        data={_satelliteReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.audio.fps 
                        }) )}
                        width='100%'
                        height={200}
                    />
                </Col>
                <Col span={8}>
                    <TimeSeriesGraph
                        title="lost"
                        data={_satelliteReceiverMetrics.map( (item, idx) => ({
                            x : item.clientTs, y: item.audio.lost 
                        }) )}
                        width='100%'
                        height={200}
                        delta={true}
                    />
                </Col>
            </Row>
 
        </div>
    );
}